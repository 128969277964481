<template>
    <v-app>
        <div>
            <span class="interNormal600Black headerFooterFontClass flexGrowNone">
                Header & Footer
            </span>
            <div class="megaMenuMainContent flexGrowNone">    
                <div class="d-flex flex-column justify-left align-left">
                    <span class="interNormal600Black boldFontClass flexGrowNone">Mega Menu</span>
                    <span class="mildFontClass flexGrowNone">Create and update mega menu here.</span>
                </div>
                <div class="dividerClass"></div>
                <div>
                    <MegaMenuComponent/>
                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
import axios from "axios";
import router from '@/router';
import MegaMenuComponent from '../../../components/MegaMenuComponent.vue';
import draggable from 'vuedraggable';
export default {
    name: 'MegaMenu',
    props:[''],
    components: {
    'draggable': draggable,
    'MegaMenuComponent': MegaMenuComponent,
},
    computed: {},
};
</script>

<style scoped>
.megaMenuMainContent {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    width: 1551px;
    height: 884px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 12px;
    flex: none;
    order: 2;
    flex-grow: 1;
}
.interNormal600Black {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    color: #333333;
}
.headerFooterFontClass {
    font-size: 28px;
    line-height: 34px;
    order: 0;
}
.dividerClass {
    align-self: stretch;
    width: 1503px;
    height: 0px;
    border: 1px dashed #BDBDBD;
    order: 0;
}
.flexGrowNone {
    flex: none;
    flex-grow: 0;
}
.boldFontClass {
    font-size: 20px;
    line-height: 24px;
    order: 0;
}
.mildFontClass {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    color: #828282;
    font-size: 16px;
    line-height: 19px;
    order: 1;
}
</style>